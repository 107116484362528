import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "layout"
import Container from "layout/Container"
import ViberModal from "elements/ViberModal"
import ButtonGroup from "../../../Elements/ButtonGroup"
import ViberSlidingModal from "../../../Elements/ViberSlidingModal"
import PersonalInformationSummary from "./PersonalInformationSummary"
import HealthSurveySummary from "./HealthSurveySummary"
import MedicinesOrderedSummary from "./MedicinesOrderedSummary"
import OrderTotal from "./MedsRequestedSummary/OrderTotal"
import UploadDocumentsSummary from "./UploadDocumentsSummary"
import ConsentAndAuthorization from "./ConsentAndAuthorization"

import { FlexmedCashlessContext } from "../../FlexmedContext/FlexmedCashlessContext"
import { handleSubmitCashlessRequest } from "../services/handleSubmitCashlessRequest"
import { useAirtableFMCFormFields } from "../../FlexmedContext/hooks/useAirtableFMCFormFields"
import { flattenNode } from "../../../../services/arrays"
import { AppContext } from "../../../../context/AppContext"

const Summary = ({ pageContext }) => {
  const { state, dispatch } = useContext(AppContext)
  const { flexmedCashlessState, flexmedCashlessDispatch } = useContext(
    FlexmedCashlessContext
  )
  const [loading, setLoading] = useState(false)

  let formFields = useAirtableFMCFormFields()
  formFields = flattenNode(formFields?.allAirtableallFlexmedCashlessFormFields)

  const handleSuccessCallback = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        isCard: true,
        heading: " ",
        headerClass: "has-background-viber",
        hideCloseButton: true,
        hideOnMobile: true,
        content: (
          <ViberModal
            callback={() => {
              navigate(pageContext.nextPath, {
                state: {
                  email: flexmedCashlessState?.email,
                },
              })
              setLoading(false)
            }}
            dispatch={dispatch}
          />
        ),
      },
    })
    dispatch({
      type: "SHOW_SLIDING_MODAL",
      payload: {
        isCard: true,
        content: (
          <ViberSlidingModal
            callback={() => {
              navigate(pageContext.nextPath, {
                state: {
                  email: flexmedCashlessState?.email,
                },
              })
              setLoading(false)
            }}
            dispatch={dispatch}
          />
        ),
      },
    })
  }

  const handleErrorCallback = () => {
    setLoading(false)
  }

  const handleOnSubmit = async () => {
    setLoading(true)
    await handleSubmitCashlessRequest({
      callback: handleSuccessCallback,
      errorCallback: handleErrorCallback,
      flexmedCashlessState,
      state,
      formFields,
      files: flexmedCashlessState?.documents,
    })
  }

  useEffect(() => {
    flexmedCashlessDispatch({
      type: "GET_CONTEXT_FROM_SESSION",
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Layout {...pageContext?.module}>
      <Container isCentered desktop={6}>
        <PersonalInformationSummary
          route={`/${pageContext?.module?.name}/order/employee-information`}
          data={flexmedCashlessState}
        />
        <HealthSurveySummary
          route={`/${pageContext?.module?.name}/order/health-survey`}
          data={flexmedCashlessState}
          flow="flexmedcashless_request"
        />
        <MedicinesOrderedSummary
          route={`/${pageContext?.module?.name}/order/search`}
          medicines={flexmedCashlessState?.medicines}
        />
        <OrderTotal medicines={flexmedCashlessState?.medicines} />
        <UploadDocumentsSummary
          route={`/${pageContext?.module?.name}/order/upload`}
          documents={flexmedCashlessState?.documents}
        />
        <ConsentAndAuthorization
          handleOnSubmit={handleOnSubmit}
          flow="flexmed_cashless"
        >
          <section className="message is-warning p-1 my-2 has-text-centered">
            Eligibility is not yet final until verified by our pharmacists via
            email.
          </section>
          <ButtonGroup
            back={{
              link: pageContext.backPath,
            }}
            next={{
              label: `Submit`,
              link: pageContext.nextPath,
              loading: loading,
              type: "submit",
              disabled:
                flexmedCashlessState?.medicines?.length === 0 ||
                flexmedCashlessState?.documents?.length === 0 ||
                flexmedCashlessState?.agreeToMechanics?.length === 0 ||
                flexmedCashlessState?.consentAndAuthorization.length < 3,
            }}
          />
        </ConsentAndAuthorization>
      </Container>
    </Layout>
  )
}

export default Summary
