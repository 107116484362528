import React from "react"
import Section from "elements/Section"
import Message from "elements/Message"
import EditDetailsButton from "elements/EditDetailsButton"
import MedicineInfoRow from "./MedsRequestedSummary/MedicineInfoRow"

const MedicinesOrderedSummary = ({ route, medicines }) => {
  return (
    <Section
      title={`Medicines Ordered`}
      addOns={{
        right: <EditDetailsButton route={route} />,
      }}
      className="my-1"
    >
      {medicines?.length > 0 ? (
        <div className="mx-1">
          <div className="columns is-vcentered is-multiline">
            {medicines?.map((medicine) => (
              <div className="column is-12">
                <MedicineInfoRow medicine={medicine} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Message color="warning">
          <p className="has-text-black ">You have not added any medicines.</p>
        </Message>
      )}
    </Section>
  )
}

export default MedicinesOrderedSummary
