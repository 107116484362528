import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTags } from "@fortawesome/free-solid-svg-icons"

// import { formatPrice } from "../../../../Epharmacy/services/computations"
import { generatePrice } from "../../../../Epharmacy/services/computations"

const MedicineInfoRow = ({ medicine }) => (
  <div className={classNames("pb-1 has-border-bottom")}>
    <div className="has-text-primary has-text-weight-bold">
      {medicine.productTitle}
    </div>
    <div className="columns is-mobile">
      <div className="column">Quantity: {medicine.qty}</div>
      <div className={classNames("column has-text-right")}>
        Php {(generatePrice(medicine) * medicine.qty).toFixed(2)}
      </div>
    </div>
  </div>
)

export const DeliveryFeeRow = ({ deliveryFee }) => (
  <div className={classNames("mt-1")}>
    <div className="columns is-mobile">
      <div className="column has-text-weight-bold">Delivery Fee</div>
      <div className={classNames("column has-text-right")}>P{deliveryFee}</div>
    </div>
  </div>
)

export const CouponDiscountRow = ({ couponCode, couponDiscount }) => (
  <div className={classNames("pb-1 has-border-bottom")}>
    <div className="columns is-mobile">
      <div className="column has-text-weight-bold">
        <span className="icon has-text-primary">
          <FontAwesomeIcon icon={faTags} />
        </span>{" "}
        Discount ({couponCode})
      </div>
      <div className={classNames("column has-text-right has-text-danger")}>
        - P{couponDiscount}
      </div>
    </div>
  </div>
)

export default MedicineInfoRow
