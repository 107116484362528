import React, { useContext, useEffect } from "react"
import classNames from "classnames"
import { accumulatePrice } from "../../../../Epharmacy/services/computations"
import { FlexmedCashlessContext } from "../../../FlexmedContext/FlexmedCashlessContext"

const OrderTotal = ({ medicines }) => {
  const { flexmedCashlessDispatch, flexmedCashlessState } = useContext(
    FlexmedCashlessContext
  )

  // update fmc state for order every time medicine cart is updated
  useEffect(() => {
    let total = accumulatePrice(medicines).toFixed(2)
    flexmedCashlessDispatch({
      type: "SAVE_ORDER_TOTAL",
      payload: total,
    })
  }, [medicines, flexmedCashlessDispatch])

  return (
    <div className="px-1 mb-3">
      <div className="columns is-mobile">
        <div className="column has-text-weight-bold">Order Total</div>
        <div className={classNames("column has-text-right")}>
          Php {flexmedCashlessState?.orderTotal}
        </div>
      </div>
    </div>
  )
}

export default OrderTotal
