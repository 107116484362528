import React, { useContext } from "react"
import { Formik, Form } from "formik"

import Section from "elements/Section"
import FormCheckbox from "elements/Form/FormCheckbox"

import {
  consentAndAuthInitialValues,
  consentAndAuthValidationSchema,
} from "./utils/formData"
import { FlexmedCashlessContext } from "../../FlexmedContext/FlexmedCashlessContext"

const termsAndConditionBlurb = `<span className="has-text-weight-normal">I agree to the <a href="/terms-and-conditions" target="_blank">terms and conditions.</a></span>`
const viberRenewalBlurb = `<span className="has-text-weight-normal">I allow MedGrocer to reach out to me for outbound teleconsults and prescription renewal.</span>`
const viberConsentBlurb = `<span className="has-text-weight-normal">I allow MedGrocer to enroll me in the Viber community for future communication and announcements.</span>`

const ConsentAndAuthorization = ({ children, handleOnSubmit, flow }) => {
  const { flexmedCashlessDispatch } = useContext(FlexmedCashlessContext)

  return (
    <Section title="Consent and Authorization">
      <div className="mx-2 mx-0-mobile mx-0-tablet">
        <Formik
          initialValues={consentAndAuthInitialValues}
          validationSchema={consentAndAuthValidationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ values }) => (
            <Form>
              <FormCheckbox
                name="consentAndAuthorization"
                values={values.consentAndAuthorization}
                options={[
                  termsAndConditionBlurb,
                  viberRenewalBlurb,
                  viberConsentBlurb,
                ]}
                onChange={(event, newValue) =>
                  flexmedCashlessDispatch({
                    type: `SAVE_AGREE_CONSENT_FLEXMED_CASHLESS`,
                    payload: newValue,
                  })
                }
                isRequired
              />
              {children}
            </Form>
          )}
        </Formik>
      </div>
    </Section>
  )
}

export default ConsentAndAuthorization
